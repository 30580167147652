<template>
    <div :style="'height:'+ height" style="position: sticky;">

      <LMap
        ref="mymap"
        :options="{zoomControl: false}"
        :zoom="zoom"
        :center="[latitude,longitude]"
      >
        <LTileLayer :url="url" />
        <LMarker
          :lat-lng="[latitude,longitude]"
          :draggable="draggable"
          @update:lat-lng="dragMarker"
        />
      </LMap>
    </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet"
export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  props:{
    draggable:{
      type:Boolean,
      default:true
    },
    latitude:{
      type: Number,
      default: -7.24917
    },
    longitude:{
      type: Number,
      default: 112.75083
    },
    height:{
      type:String,
      default:'50vh'
    },
    zoom:{
      type:Number,
      default:16
    },
    sync:{
      type:String,
      default:'address'
    }

  },
  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      bounds: null,
      // marker:[-7.24917, 112.75083],
      // marker:[this.latitude, this.longitude],
      // center:[-7.24917, 112.75083]
    }
  },
  mounted() {
    // marker.push(this.latitude)
      // this.marker=[-7.24917, 112.75083]

    // if( this.latitude != 0 && this.logintude != 0){
    //   this.marker = [this.latitude,this.logintude]
    //   this.center = [this.latitude,this.logintude]
    // }
  },
  created(){
    setTimeout(() => {
      this.$refs.mymap.mapObject.invalidateSize(); 
    }, 100);
  },
  methods: {
    addMarker(event) {
      alert(JSON.stringify(event.latlng))
      // console.log(event.latlng)
      // this.marker = event.latlng
      // this.marker =event.latlng
      this.$emit('updateMap', event.latlng)
    },
    dragMarker(latlng){
      latlng.ref = this.sync
      this.$emit('updateMap', latlng)
    }
  }
}
</script>