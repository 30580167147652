<template>
  <div>
    
    <b-row cols="12">
      <b-col
        md="7"
        class="my-1"
        cols="7"
      >
        <h3 class="mr-1">
          {{ title }}
        </h3>
      </b-col>

      <b-col
        md="5"
        class="my-1"
        cols="5"
      >
        <b-form-group
          label=""
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <!-- <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append> -->
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      variant="primary"
      :opacity="0.5"
      rounded="sm"
    >

      <b-table
        hover
        selectable
        select-mode="single"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        @row-selected="onRowSelected"
      >
        <template
          v-for="(_, slot) of $scopedSlots"
          v-slot:[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="recordsTotal"
        :per-page="pageSize"
        aria-controls="my-table"
      />
    </b-overlay>
  </div>
</template>
<script>
import {
  BTable, BRow, BCol, BFormGroup, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,BOverlay
} from 'bootstrap-vue'


export default {
  components: {
    BTable,
    BCol,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BRow,
    BOverlay,
 
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    dataurl: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    fieldsform: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      rowSelected:{},

      currentPage: 1,
      sortBy: '',
      sortDirection: true,
      recordsTotal: 0,
      pageSize: 10,
      filter: '',
      items:[],
      loading:false,
      dataitem:{}
    }
  },
  watch: {
    filter() {
      this.fetchData()
    },
    currentPage() {
      this.fetchData()
    },
    sortDirection() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading =true
      var params ={}
      
      // set paging
      params["start"] = (this.currentPage-1) * this.pageSize 
      params["length"] = this.pageSize 
      
      // set sort
      if(this.sortBy == '')
        params["sort[created_at]"] = "desc" 
      else
        params["sort["+this.sortBy+"]"] = this.sortDirection ? 'asc' :'desc'

      // set search
      if(this.filter != '')
        params["search"] = this.filter

          
      this.$http.get(this.dataurl,{params:params})
        .then(res  => {
          this.items = res.data.data
          this.recordsTotal = res.data.recordsTotal
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false) 

    },
    onRowSelected(val) {
      this.$emit("onRowSelected", val[0])
    },
  },
}
</script>
