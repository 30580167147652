<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      dataurl:"/tire-axle",
      baseroute:"operasional-tire-axle",
      title:"Poros Ban Kendaraan",
      fields : [
        { key: 'name', label: 'Nama', type: 'input', rules:'required' },
        { key: 'code', label: 'Kode', type: 'input', rules:'required' },
        { key: 'amount', label: 'Jumlah Ban', type: 'number', rules:'required' }
      ]
    }
  },
}
</script>