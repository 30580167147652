<template>
  <validation-observer ref="simpleRules">
    <b-form :ref="formidentifier">
      <h2 class="mr-1">
        {{ title }}
      </h2>
      <br />
      <b-row>
        <b-col v-for="(field, index) in fields" :key="index" :cols="rowcol">
          <h4 v-if="field.key == 'section'" style="margin-top: 0">{{ field.desc }} <br></h4>
          <hr v-else-if="field.key == 'hr'" style="margin-top: 0" />
          <!-- with helper text -->
          <b-form-group
            v-if="field.key != 'hr' && field.key != 'section'"
            :label-cols-md="labelcol"
            :label="field.label"
            :label-for="field.key"
            :description="field.desc"
          >
            <validation-provider
              v-if="field.key != 'hr'"
              #default="{ errors }"
              :name="field.label"
              :rules="field.rules"
            >
              <b-form-input
                v-if="field.type == 'input'"
                :id="field.key"
                v-model="model[field.key]"
                :plaintext="false"
                :placeholder="field.placeholder"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-input
                v-else-if="field.type == 'input-number'"
                :id="field.key"
                v-model="model[field.key]"
                :plaintext="false"
                type="number"
                min="0"
                :placeholder="field.placeholder"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-input
                v-else-if="field.type == 'input-year'"
                :id="field.key"
                v-model="model[field.key]"
                :plaintext="false"
                type="number"
                min="0"
                :formatter="formatYear"
                :placeholder="field.placeholder"
                :state="errors.length > 0 ? false : null"
              />
              <b-form-input
                v-else-if="field.type == 'password'"
                :id="field.key"
                v-model="model[field.key]"
                :type="'password'"
                :disabled="!model['create_user'] && field.disabled"
                :placeholder="field.placeholder"
                :state="errors.length > 0 ? false : null"
              />
              <form-autocomplate
                v-else-if="field.type == 'autocomplate'"
                :id="field.key"
                :value-key="field.key"
                :value="model[field.key]"
                :placeholder="field.placeholder"
                :state="errors.length > 0 ? false : null"
                :index-value="field.indexValue"
                :dataUrl="field.dataurl"
                :objectView="field.objectView"
                @change="changeAutocomplate"
                @input="changeInput"
              />
              <b-form-select
                v-else-if="field.type == 'select'"
                v-model="model[field.key]"
                :options="field.options"
                value-field="id"
                text-field="name"
              />
              <form-v-select
                v-else-if="field.type == 'select2'"
                v-model="model[field.key]"
                :selected="model[field.key]"
                :dataurl="field.dataurl"
                :item_text="field.key_value"
                :placeholder="field.placeholder ? field.placeholder : 'Select Value'"
              />
              <baseSelect
                v-else-if="field.type == 'select-ajax'"
                :id="field.key"
                v-model="model[field.key]"
                :dataurl="field.dataurl"
                :title="field.label"
                :datafields="field.dataFields"
                :datavalue="model[field.data_value]"
              />
              <baseSelectSingle
                v-else-if="field.type == 'select-single'"
                :id="field.key"
                v-model="model[field.key]"
                :dataurl="field.dataurl"
                :title="field.label"
              />
              <baseSelectMulti
                v-else-if="field.type == 'select-multi'"
                :id="field.key"
                v-model="model[field.key]"
                :dataurl="field.dataurl"
                :title="field.title"
                :datavalue="model[field.data_value]"
              />
              <cleave
                v-else-if="field.type == 'number'"
                :id="field.key"
                v-model="model[field.key]"
                class="form-control"
                :raw="true"
                :options="options.number"
                :placeholder="field.placeholder"
              />
              <b-form-textarea
                v-else-if="field.type == 'text'"
                :id="field.key"
                v-model="model[field.key]"
                :placeholder="field.placeholder"
                rows="3"
              />
              <div v-else-if="field.type == 'p'">
                <p>
                  <strong>{{ field.title }}</strong>
                </p>
                <p>{{ field.value }}</p>
              </div>
              <b-form-checkbox
                v-else-if="field.type == 'bool'"
                v-model="model[field.key]"
                switch
                >{{ field.title }}</b-form-checkbox
              >
              <flat-pickr
                v-else-if="field.type == 'date'"
                v-model="model[field.key]"
                :value="setDate(field.key, field.val)"
                class="form-control"
              />
              <basemap
                v-else-if="field.type == 'map'"
                :latitude="lat"
                :longitude="lng"
                :sync="field.ref"
                @updateMap="updateMap"
              />
              <form-file v-else-if="field.type == 'file'" v-model="model[field.key]" />
              <form-file v-else-if="field.type == 'image'" v-model="model[field.key]" />
              <small v-if="field.type" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <hr style="margin-top: 0" />
          <b-form-group label-cols-md="4" label="">
            <b-button
              class="mr-1"
              size="sm"
              variant="warning"
              type="button"
              @click.prevent="cancel"
            >
              <span v-if="ismodal">Cancel</span>
              <span v-else>Batal</span>
            </b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="submit"
              :disabled="loading"
              @click.prevent="validationForm"
            >
              <span v-if="loading">
                <b-spinner small />
                Loading...
              </span>
              <span v-else>Simpan</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import baseSelect from '@/views/base/BaseSelect.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormAutocomplate from '@/views/base/form/FormAutocomplate.vue'
import baseSelectMulti from '@/views/base/BaseSelectMulti.vue'
import baseSelectSingle from '@/views/base/BaseSelectSingleData.vue'
import basemap from '@/views/base/BaseMap.vue'
import FormFile from './form/FormFile.vue'
import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormSelect,
    Cleave,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
    flatPickr,
    baseSelect,
    baseSelectMulti,
    baseSelectSingle,
    basemap,
    FormFile,
    FormVSelect,
    FormAutocomplate,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    ismodal: {
      type: Boolean,
      default: false,
    },
    isdetails: {
      type: Boolean,
      default: false,
    },
    custom_save: {
      type: Boolean,
      default: false,
    },
    dataitem: {
      type: Object,
      default: function () {
        return {}
      },
    },
    formidentifier: {
      type: String,
      default: 'baseform',
    },
    rowcol: {
      type: Number,
      default: 12,
    },
    labelcol: {
      type: Number,
      // set 0 to vertical
      default: 4,
    },
    posturl: {
      type: String,
      default: '',
    },
    posturl2: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    reffFields: {
      type: Array,
      default() {
        return []
      },
    },
    dataDetail: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      loading: false,
      model: { address: '' },
      lat: -7.24917,
      lng: 112.75083,
      // validation
      required,
      email,
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      params: null,
    }
  },
  beforeMount() {
    if (this.$route.params.id != null)
      this.$http.get(this.posturl + '/' + this.$route.params.id).then(res => {
        this.model = res.data
        this.$emit('showData', this.model)
        if (res.data.latitude) {
          this.lat = parseFloat(res.data.latitude)
          this.model.latitude = parseFloat(res.data.latitude)
        } else {
          this.model.latitude = -7.24917
          this.lat = -7.24917
        }
        if (res.data.longitude) {
          this.lng = parseFloat(res.data.longitude)
          this.model.longitude = parseFloat(res.data.longitude)
        } else {
          this.model.longitude = 112.75083
          this.lng = 112.75083
        }
      })
  },
  mounted() {
    if (Object.keys(this.dataitem).length != 0) {
      this.model = Object.assign({}, this.dataitem)
    }
  },
  methods: {
    formatYear(e){
     return String(e).substring(0,4);
    },
    validationForm() {
      if (this.custom_save) {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.loading = true
            this.filterDataSubmit().then(() => {
              let paramData = this.params
              this.$http
                .put(this.posturl2, paramData)
                .then(() => {
                  this.successSubmit()
                })
                .catch(err => {
                  this.errorSubmit(err)
                })
                .finally(() => {
                  this.loading = false
                })
            })
          } else {
            this.$bvToast.toast('Please correct form input before submit', {
              title: 'Form Error',
              solid: true,
              variant: 'danger',
            })
          }
        })
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.loading = true
            this.filterDataSubmit().then(() => {
              let paramData = this.params
              if (this.$route.params.id == null || !this.model['id']) {
                this.$http
                  .post(this.posturl, paramData)
                  .then(() => {
                    this.successSubmit()
                  })
                  .catch(err => {
                    this.errorSubmit(err)
                  })
                  .finally(() => {
                    this.loading = false
                  })
              } else {
                this.$http
                  .put(this.posturl + '/' + this.model['id'], paramData)
                  .then(() => {
                    this.successSubmit()
                  })
                  .catch(err => {
                    this.errorSubmit(err)
                  })
                  .finally(() => {
                    this.loading = false
                  })
              }
            })
          } else {
            this.$bvToast.toast('Please correct form input before submit', {
              title: 'Form Error',
              solid: true,
              variant: 'danger',
            })
          }
        })
      }
    },

    async filterDataSubmit() {
      let newData = {}
      let isUpload = false
      let fileUpload = null
      for (let index = 0; index < this.fields.length; index++) {
        let row = this.fields[index]
        if (row.type == 'map') {
          newData['latitude'] = this.model['latitude']
          newData['longitude'] = this.model['longitude']
        } else if (row.type == 'file') {
          if (!JSON.stringify(this.model[row.key]).includes('/storage/')){
            let formData = new FormData()
            formData.append('file', this.model[row.key])
            let res = await this.$http.post('upload/file', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .catch( err => {
              const msg = err.response.data.message
              this.$bvToast.toast((msg)?msg:'File not found', {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
              this.loading = false
            })
            newData[row.key] = res.data.path
          }
          continue
        } else if (row.type == 'image') {
          if (!JSON.stringify(this.model[row.key]).includes('/storage/')){
            let formData = new FormData()
            formData.append('file', this.model[row.key])
            let res = await this.$http.post('upload/image', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .catch( err => {
              const msg = err.response.data.message
              this.$bvToast.toast((msg)?msg:'File not found', {
                title: 'Error',
                solid: true,
                variant:'danger'
              })
              this.loading = false
            })
            newData[row.key] = res.data.path
          }
          continue
        } else if (row.type == 'details') {
          newData[row.key] = this.dataDetail
          continue
        } else if (row.key == 'activity_type') {
          continue
        } else if (row.key == 'hr') {
          continue
        } else if (this.model[row.key] == null) {
          row.type == 'bool'
            ? (newData[row.key] = false)
            : row.type == 'number'
            ? (newData[row.key] = 0)
            : (newData[row.key] = '')
          continue
        } else if (typeof this.model[row.key] === 'object') {
          if (Array.isArray(this.model[row.key])) {
            let arrData = this.model[row.key].map(e => e.id)
            newData[row.key] = arrData
          } else {
            if (row.key == 'city') {
              newData[row.key] = this.model[row.key].name
            } else {
              newData[row.key + '_id'] = this.model[row.key].id
            }
          }
        } else {
          newData[row.key] = this.model[row.key]
        }
      }

      // if (isUpload) {
      //   let formData = new FormData()
      //   //  console.log(fileUpload)
      //   formData.append('file', fileUpload)
      //   let res = await this.$http.post('upload/file', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //     },
      //   })

      //     console.log(res)
      // }
      if(this.reffFields.length > 0){
        this.reffFields.forEach(field => {
          newData[field.key] = field.value
        })
      }
      this.params = newData
    },

    successSubmit() {
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      // this.$bvToast.toast('Submit Success', {
      //   title: 'Form submit',
      //   solid: true,
      //   variant:'success'
      // })
      if (this.ismodal) this.$emit('onsubmite', true)
      else this.$router.go(-1)
      // if(Object.keys(this.dataitem).length == 0){
      //   this.model = {}
      // }

      this.$store.dispatch('sendMessage', '1')
    },
    errorSubmit(err) {
      const msg = err.response.data.message
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
    cancel() {
      if (this.ismodal) this.$emit('onsubmite', false)
      else this.$router.go(-1)
    },
    async updateMap(latlong) {
      this.lat = parseFloat(latlong.lat)
      this.lng = parseFloat(latlong.lng)
      this.model['latitude'] = latlong.lat
      this.model['longitude'] = latlong.lng
      let { data } = await this.$http.get(
        'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' +
          latlong.lat +
          '&lon=' +
          latlong.lng
      )
      if (latlong.ref != '') this.model[latlong.ref] = data.display_name
    },
    setDate(key, val) {
      if (val == 'now') this.model[key] = moment().format('Y-MM-D')
      else if (val != null && val != '') this.model[key] = val
    },
    changeAutocomplate(val) {
      this.model[val.valueKey] = val.display_name
      if(val.lat || val.lon){
        this.lat = parseFloat(val.lat)
        this.lng = parseFloat(val.lon)
        this.model['latitude'] = val.lat
        this.model['longitude'] = val.lon
      }
      
    },
    changeInput(val) {
      this.model[val.valueKey] = val.value
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
