<template>
  <div>
    <b-button
      v-b-modal="'modal-select-'+id"
      size="sm"
      variant="success"
    >
      {{ value[fields[0].key] || 'Pilih' }}
    </b-button>
    <b-modal
      :id="'modal-select-'+id"
      centered
      title="Pilih"
      hide-footer
    >
      <div><basetable
        :dataurl="dataurl"
        :fields="fields"
        :title="title"
        @onRowSelected="onRowSelected"
      /></div>
    </b-modal>
  </div>
</template>
<script>
import {BButton} from 'bootstrap-vue'
import basetable from '@/views/base/BaseSelectTable.vue'
export default {
  components: {
    basetable,
    BButton,
    
  },
  props:{
    value:{
      type: Object,
      default: function () {
        return {}
      }
    },
    id: {
      type: String,
      default: '',
    },
    dataurl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    datavalue:{
      type: Object,
      default: function () {
        return {}
      }
    },
    datafields:{
      type: Array,
      default: function () {
        return [{ key: 'name', label: 'nama' }]
      }
    }
  },
  data() {
    return {
      fields: this.datafields,
    }
  },
  mounted() {
    // console.log(this.datavalue)
    // this.labeldata = this.datavalue
  },
  methods:{
    onRowSelected(val) {
      this.$bvModal.hide('modal-select-'+this.id)
      this.$emit('input', val)
      // this.$emit('updateLabel', val)
    },
  }
}
</script>
<style lang="">
  
</style>