<template>
  <div>

    <b-button
      v-for="(item, index) in value"
      :key="index"
      size="sm"
      variant="outline-primary"
      class="mr-1"
      @click="remove(index)"
    >
      {{ item['name'] }}
    </b-button>
    <!-- <div class="mt-1"> -->
    <b-button
      v-b-modal="'modal-select-'+id"
      size="sm"
      variant="success"
    >
      {{ 'Pilih' }}
    </b-button>

    <!-- </div> -->
    <b-modal
      :id="'modal-select-'+id"
      centered
      title="Pilih"
      hide-footer
    >
      <div><basetable
        :dataurl="dataurl"
        :fields="fields"
        :title="title"
        @onRowSelected="onRowSelected"
      /></div>
    </b-modal>
  </div>
</template>
<script>
import {BButton} from 'bootstrap-vue'
import basetable from '@/views/base/BaseSelectTable.vue'
export default {
  components: {
    basetable,
    BButton,
  },
  props:{
    value:{
      type: Array,
      default: function () {
        return []
      }
    },
    id: {
      type: String,
      default: '',
    },
    dataurl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    datavalue:{
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'name' },
      ],
    }
  },
  mounted() {
    // console.log(this.datavalue)
    // this.labeldata = this.datavalue
  },
  methods:{
    remove(index){
      let a = this.value
      a.splice(index,1)
      this.$emit('input', a)
    },    
    onRowSelected(val) {
      this.$bvModal.hide('modal-select-'+this.id)
      let a = this.value
      a.push(val)
      this.$emit('input', a)
      // this.$emit('updateLabel', val)
    },
  }
}
</script>
<style lang="">
  
</style>